import helpCenterService from "../services/helpCenterService";
import { IHelpCentreWidgetInternalOptions } from "../interfaces";
import { useNavigate } from "react-router-dom";
import navigationService from "../services/navigationService";
import { useTranslation } from "react-i18next";

export const NavBarHelpCentrePanel = () => {
    const navigate = useNavigate();
    const { t } = useTranslation("general");

    function handleOnClickHelpButton() {
        navigationService.getHelpCenterOptions().then((data: IHelpCentreWidgetInternalOptions) => {
            if (data == null) {
                navigate("/error/500");
                return;
            }

            var options = {
                applicationCode: data.ApplicationCode,
                source: data.Source,
                userLanguageCode: data.UserLanguageCode,
                companyCountryCode: data.CompanyCountryCode,
                companyId: data.CompanyId,
            }

            helpCenterService.initWidgetScript(options, data.WidgetLocationUrl);
            if (window.supportPanelWidget)
                window.supportPanelWidget.open(options);
            else
                setTimeout(() => {
                    window.supportPanelWidget?.open(options);
                }, 500);
        })
    }

    return (
        <a className="button-icon"
            href="#"
            role="button"
            aria-label={t("Help")}
            title={t("Help")}
            onClick={handleOnClickHelpButton}>
            <span className="vismaicon vismaicon-dynamic vismaicon-menu vismaicon-help"
                aria-hidden="true"
                data-testid="help-button"></span>
        </a>
    );
}