import './App.css';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useParams } from "react-router-dom";
import { ReportsPage, InternalErrorPage, SettingsPage } from './pages';
import { HelpCentreWidget, HelpCentreWidgetOptions, HelpCentreWidgetSelectors } from './interfaces/HelpCentre';
import { ProtectedRoute } from './navigation/ProtectedRoute';
import { ReportDetailsPage } from './pages/reportDetails/ReportDetailsPage';
import { AuthenticationStatusPage } from './pages/reports/authentication/AuthenticationStatusPage';
import { AuthenticationActionEnum, TaxCardType } from './utils/constants';
import { QuickAuthenticationPage } from './pages/reports/quickAuthentication/QuickAuthenticationPage';
import { FetchFeedbackPage } from './pages/reports/feedback/FetchFeedbackPage';
import { TaxCardAuthenticationPage } from './pages/taxCard/TaxCardAuthenticationPage';
import { DownloadAltinnTaxCardPage } from './pages/taxCard/DownloadAltinnTaxCardPage';
import { AboutPage } from './pages/about/AboutPage';
import { ThirdPartyComponentPage } from './pages/about/ThirdPartyComponentPage';
import { reactPlugin } from './appInsights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { IWootric } from './interfaces/Wootric';

declare global {
  interface Window {
    supportPanelWidget?: HelpCentreWidget;
    vncWidget?: any;
    supportPanelWidgetInit: (
      selectors: HelpCentreWidgetSelectors | null,
      options: HelpCentreWidgetOptions | null
    ) => HelpCentreWidget;
    wootric?: any;
    wootricSettings: IWootric | null;
  }
}

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/">
            <Route index element={<Navigate to="report" />} />
            <Route path="autoreport" element={<NavigateToNewRoutes path="report" menuSwitch='app' />} />
            <Route path="report" element={<ReportsPage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="report/:id/details" element={<ReportDetailsPage />} />
            <Route path="report/:id/feedback" element={<FetchFeedbackPage />} />
            <Route path="report/feedback/" element={<FetchFeedbackPage />} />
            <Route path="report/:id/authenticate" element={<QuickAuthenticationPage action={AuthenticationActionEnum.Authenticate} />} />
            <Route path="logout/:id" element={<QuickAuthenticationPage action={AuthenticationActionEnum.Logout} />} />
            <Route path="authenticateStatus/:status" element={<AuthenticationStatusPage action={AuthenticationActionEnum.Authenticate} />} />
            <Route path="logoutStatus/:status" element={<AuthenticationStatusPage action={AuthenticationActionEnum.Logout} />} />
            <Route path="altinnTaxCard/:id" element={<TaxCardAuthenticationPage taxCardType={TaxCardType.Altinn} />} />
            <Route path="altinnTaxCard/:id/download" element={<DownloadAltinnTaxCardPage />} />
            <Route path="taxCard/:id/authenticate" element={<TaxCardAuthenticationPage taxCardType={TaxCardType.AnyTaxCard} />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="3rdpartycomponents" element={<ThirdPartyComponentPage />} />
          </Route>
          {["app", "dlg"].map(menuSwitch =>
            <Route key={menuSwitch} path={`/autoreport/${menuSwitch}`}>
              <Route index element={<NavigateToNewRoutes path='report' menuSwitch={menuSwitch} />} />
              <Route path="report" element={<NavigateToNewRoutes path='report' menuSwitch={menuSwitch} />} />
              <Route path="settings" element={<NavigateToNewRoutes path='settings' menuSwitch={menuSwitch} />} />
              <Route path="altinnResponse/" element={<NavigateToNewRoutes path='report/feedback' menuSwitch={menuSwitch} />} />
              <Route path="altinnResponse/:id" element={<NavigateToNewRoutes path='report/{idPlaceholder}/feedback' menuSwitch={menuSwitch} />} />
              <Route path="altinnResponse/:id/download" element={<NavigateToNewRoutes path='report/{idPlaceholder}/feedback' menuSwitch={menuSwitch} />} />
              <Route path="altinnAMessage/" element={<NavigateToNewRoutes path='report/feedback' menuSwitch={menuSwitch} />} />
              <Route path="altinnAMessage/:id" element={<NavigateToNewRoutes path='report/{idPlaceholder}/feedback' menuSwitch={menuSwitch} />} />
              <Route path="upload/:id/authenticate" element={<NavigateToNewRoutes path='report/{idPlaceholder}/details' menuSwitch={menuSwitch} />} />
              <Route path="report/:id/authenticate" element={<NavigateToNewRoutes path='report/{idPlaceholder}/authenticate' menuSwitch={menuSwitch} />} />
              <Route path="logout/:id" element={<NavigateToNewRoutes path='logout/{idPlaceholder}' menuSwitch={menuSwitch} />} />
              <Route path="altinnTaxCard/:id/AuthenticateRequest" element={<NavigateToNewRoutes path='altinnTaxCard/{idPlaceholder}' menuSwitch={menuSwitch} />} />
              <Route path="altinnTaxCard/:id/Download" element={<NavigateToNewRoutes path='altinnTaxCard/{idPlaceholder}/download' menuSwitch={menuSwitch} />} />
              <Route path="altinnTaxCard/:id" element={<NavigateToNewRoutes path='altinnTaxCard/{idPlaceholder}' menuSwitch={menuSwitch} />} />
              <Route path="taxcard/:id/authenticate" element={<NavigateToNewRoutes path='taxCard/{idPlaceholder}/authenticate' menuSwitch={menuSwitch} />} />
            </Route>
          )}
          <Route path="/autoreport//dlg/altinnTaxCard/:id/Download" element={<NavigateToNewRoutes path='altinnTaxCard/{idPlaceholder}/download' menuSwitch={"dlg"} />} />
          <Route path="/autoreport//app/altinnTaxCard/:id/Download" element={<NavigateToNewRoutes path='altinnTaxCard/{idPlaceholder}/download' menuSwitch={"app"} />} />
        </Route>
        <Route path="*" element={<Navigate to="/error/404" />} />
        <Route path="/error/:code" element={<InternalErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

const NavigateToNewRoutes = ({ path, menuSwitch }: { path: string, menuSwitch: string }) => {
  const { search } = useLocation();
  const newSearch = search ? search.replace(/&*menu=([a-zA-Z0-9]*)/g, '') + "&" : "?";
  const params = useParams();
  const id = params.id;
  if (id)
    path = path.replace("{idPlaceholder}", id);

  var newPath = `/${path}${newSearch}menu=${menuSwitch === "app" ? true : false}`;

  return (
    <Navigate to={newPath} />
  )
}

export default withAITracking(reactPlugin, App, undefined, "h-100");
