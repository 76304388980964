import axiosUtils from '../utils/axios';
import { IHelpCentreWidgetInternalOptions, IPage, IPageQuery } from '../interfaces';
import { IContext } from '../interfaces';

const baseUrl = "menu"

const switchContext = async (contextId: string): Promise<IContext> => {
    const result = await axiosUtils.instance.post<IContext>(`api/${baseUrl}/switchContext?context=${contextId}`);
    return result.data;
}

const getPage = async (query: IPageQuery): Promise<IPage<IContext>> => {
    const result = await axiosUtils.instance.get<IPage<IContext>>(`api/${baseUrl}/getCompanies`, { params: { pageNumber: query.PageNumber, pageSize: query.PageSize, ...query.Filters } });
    return result.data;
}

const getHelpCenterOptions = async (): Promise<IHelpCentreWidgetInternalOptions> => {
    const result = await axiosUtils.instance.get<IHelpCentreWidgetInternalOptions>(`api/${baseUrl}/helpCenterWidgetOptions`);
    return result.data;
}

export default {
    getPage,
    switchContext,
    getHelpCenterOptions,
}