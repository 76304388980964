import { HelpCentreWidgetOptions } from "../interfaces";

const loadWidgetScript = async (widgetLocation: string) => {
    const widgetScript = document.querySelector("script#widgetScript");
    if (!widgetScript) {
        let cdnAddress = widgetLocation;
        const scriptTag = document.createElement("script");
        scriptTag.src = `${cdnAddress}/widget.min.js`;
        scriptTag.crossOrigin = "anonymous";
        scriptTag.id = "widgetScript";
        scriptTag.async = true;
        document.head.appendChild(scriptTag);
    }
};

const initWidgetScript = (options: HelpCentreWidgetOptions, widgetLocation: string) => {
    if (options) {
        loadWidgetScript(widgetLocation);

        if (window.supportPanelWidgetInit) {
            const hcRef: Element | null = document.querySelector("#helpwidget-help");
            if (!hcRef) {
                window.supportPanelWidget = window.supportPanelWidgetInit(null, options);
            } else {
                window.supportPanelWidget?.reInit(options);
            }
        } else {
            setTimeout(() => {
                initWidgetScript(options, widgetLocation);
            }, 500);
        }
    }
};

export default {
    loadWidgetScript,
    initWidgetScript
}